import React, { useState, useEffect } from "react";
import {
  Spinner,
  Alert,
  Modal,
  Button, Form
} from 'react-bootstrap';
// import mockData from "./mockData.json";
import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';

interface DataItem {
  accountid: string;
  firstname: string;
  lastname: string;
  specialty: string;
  email: string;
  phone: string;
  createdon: string;
  appliedon: string;
  appliedonDAte: string;
  appliedonTime: string;
  status: string;
  idproof: string;
  medlicense: string;
}

const VerificationPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [actionloading, setactionLoading] = useState<string | false>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>("Pending Verification");

  // React.useEffect(() => {
  //   // Simulate fetching data
  //   setUsers(mockData);
  // }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setError(null);
      setLoading(true);
      let filterBy = 'started';
      if (selectedFilter === 'Resubmit') filterBy = 'resubmit';
      if (selectedFilter === 'Rejected') filterBy = 'fake';
      if (selectedFilter === 'Verified') filterBy = 'verified';

      const endDate = new Date(); endDate.setDate(endDate.getDate() + 1);
      const response = await axios.post<{success: boolean, data: DataItem[]}>(`${globalURLS.baseURL}ADgetAllUsersForVerification`, {dataonly: true, startDate: '2021-06-01', endDate: `${endDate.toISOString().split('T')[0]}`, filterBy, sortBy: 'mostrecent' }, globalURLS.config);
      if (!response.data.success) throw new Error("Some problem occurred");
      setUsers(response.data.data);
      setLoading(false);
    } catch (err: any) {
      setError(err.message || 'Error fetching data');
      setLoading(false);
    }
  };

  const handleAction = async (accountid: string, status: string) => {
    try {
      setError(null);
      setactionLoading(accountid);
      const response = await axios.post<{success: boolean, data: {responses: any, errors: any}}>(`${globalURLS.baseURL}ADupdateVerificationStatus`, { users: [{accountid, status}] }, globalURLS.config);
      if (!response.data.success || response.data.data?.errors?.length) throw new Error("Some problem occurred");
      setUsers((users: DataItem[]) => {
        return users.map((val: DataItem) => val.accountid === accountid ? { ...val, status } : val)
      });
      setactionLoading(false);
      alert("Status has been updated")
    } catch (err: any) {
      // setError(err.message || 'Error fetching data');
      alert(err.message || 'Error fetching data')
      setactionLoading(false);
    }
  };

  const handleFileClick = (fileUrl: string) => {
    const isPdfFile = fileUrl.toLowerCase().endsWith(".pdf");
    // setIsPdf(isPdfFile);
    if (isPdfFile) {
      setTimeout(() => {
        window.open(fileUrl, "_blank");
      }, 500); 
    }
    else {
      setSelectedFile(fileUrl);
      setShowModal(true);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(event.target.value);
  };

  const handleFilterSubmit = () => {
    fetchData();
  }

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <Spinner animation="border" role="status" />
        <span className="ms-2">Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <Alert variant="danger">Error: {error}</Alert>
      </div>
    );
  }

  return (
    <div className="d-flex">
    <div
        className="main-content"
        style={{
          width: "100%",  // 80% width for the main content
        }}
      >
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className='mr-2 mr-lg-2' style={{color: '#343a40'}}>Medflix User Verification</h3>

        <div className="d-flex align-items-center gap-2">
          {/* Sort Dropdown */}
          {/* <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle w-100"
              type="button"
              id="sortDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
                Sort by
                </button>
                <ul className="dropdown-menu" aria-labelledby="sortDropdown">
              <li>
                <button className="dropdown-item" onClick={() => console.log('Sort by Name')}>
                  Name
                </button>
              </li>
              <li>
                <button className="dropdown-item" onClick={() => console.log('Sort by Date')}>
                  Date
                </button>
              </li>
              <li>
                <button className="dropdown-item" onClick={() => console.log('Sort by Status')}>
                  Status
                </button>
              </li>
            </ul>
          </div> */}

           {/* Search Bar */}
        {/* <input
          type="text"
          className="form-control w-100"
          placeholder="Search here"
        /> */}

            {/* Filter Section */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="fw-bold">Filter By: </span>
            <Form.Select 
              value={selectedFilter} 
              onChange={handleFilterChange} 
              className="w-auto"
            >
              <option value="Pending Verification">Pending Verification</option>
              <option value="Resubmit">Resubmit</option>
              <option value="Rejected">Rejected</option>
              {/* <option value="Verified">Verified</option> */}
            </Form.Select>
            <Button variant="primary" onClick={handleFilterSubmit}>Submit</Button>
          </div>
      </div>
      </div>
      
      <table className="table table-striped table-hover" style={{backgroundColor: '#f1f1f1'}}>
        <thead>
          <tr>
            <th scope="col">No. ({users.length})</th>
            <th scope="col">Account ID</th>
            <th scope="col">Status</th>
            <th scope="col">Name</th>
            <th scope="col">Specialty</th>
            <th scope="col">Applied On</th>
            <th scope="col">Id-Proof</th>
            <th scope="col">Med-License</th>
            <th scope="col">Action Items</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.accountid}>
              <td>{index + 1}</td>
              <td>{user.accountid}</td>
              <td>{user.status}</td>
              <td>{`${user.firstname} ${user.lastname}`}</td>
              <td>{user.specialty}</td>
              <td>{`${user.appliedonDAte}`}</td>
              <td>
                <span 
                  className="text-primary d-block cursor-pointer" 
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleFileClick(user.idproof)}
                >
                  View ID
                </span>
              </td>
              <td>
                <span 
                  className="text-primary d-block cursor-pointer" 
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleFileClick(user.medlicense)}
                >
                  View License
                </span>
              </td>
              {
                actionloading === user.accountid ?  (
                  <div className="container mt-5 text-center">
                    <Spinner animation="border" role="status" />
                    {/* <span className="ms-2">Loading...</span> */}
                  </div>
                ) : (
                  <td>
                    <button
                      className="btn btn-success btn-sm me-2"
                      onClick={() => handleAction(user.accountid, "verified")}
                      style={{marginBottom: 10}}
                    >
                      &#x2713; Accept
                    </button>
                    <button
                      className="btn btn-danger btn-sm me-2"
                      onClick={() => handleAction(user.accountid, "fake")}
                      style={{ marginBottom: 10 }}
                    >  ✘ Reject
    
                    </button>
                    <button
                      className="btn btn-warning btn-sm"
                      onClick={() => handleAction(user.accountid, "resubmit")}
                    >
                      Resubmit
                    </button>
                  </td>
                )
              }
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{height:200}}></div>
    </div>

      {/* File Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>File Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedFile ? (
            <img 
              src={selectedFile} 
              alt="Document Preview" 
              className="img-fluid"
            />
          ) : (
            <p>No file selected</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedFile && (
            <Button variant="primary" href={selectedFile} target="_blank" rel="noopener noreferrer">
              Download File
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default VerificationPage;
