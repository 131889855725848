/* eslint-disable no-undef */
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import VerificationPage from "./Pages/userVerification/VerificationPage";
// import "bootstrap/dist/css/bootstrap.min.css"; 

// SideBar Component
import Sidebar from './Components/Sidebar/Sidebar';

// Pages
import Discussion from './Pages/Discussion/Discussion';
import NewsFeed from './Pages/NewsFeed/NewsFeed';
import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Login/Login';
import Reports from './Pages/Reports/Reports';
import Banner from './Pages/Banner/Banner';
import User from './Pages/User/User';
import Club from './Pages/Club/Club';
import Notification from './Pages/Notification/Notification';
import Quiz from './Pages/Quiz/Quiz';
import AdsDash from './Pages/AdsDash/AdsDash';
import LinkZap from './Pages/LinkZap/index';
import WATemplatesHelper from './Pages/WATemplatesHelper/index';

//Context Providers
import { FeedContextProvider } from './Context/FeedContext';
import { ReportContextProvider } from './Context/ReportContext';
import { GlobalContextProvider } from './Context/GlobalContext';
import { BannerContextProvider } from './Context/BannerContext';
import { UserContextProvider } from './Context/UserContext';
import { ClubContextProvider } from './Context/ClubContext';
import { NotificationContextProvider } from './Context/NotificationContext';

//Using Contexts
import { useGlobalContext } from './Context';
import PlexusMD from './Pages/PlexusMD/PlexusMD';
import { PmdContextProvider } from './Context/PmdContext';
import FloatingAddButton from './Components/Common/FloatingAddButton/FloatingAddButton';
import UploaderModal from './Components/Common/Uploader/UploaderModal';
import Messenger from './Components/Common/Messenger/Messenger';

function App() {
    const [user, setUser] = useState<any>();
    const { authDetails } = useGlobalContext();
    const isLoggedIn = user?.token;

    useEffect(() => {
        const theUser = localStorage.getItem('user');

        if (theUser && !theUser.includes('undefined')) {
            setUser(JSON.parse(theUser));
        }
    }, [authDetails]);



//    <VerificationPage />   left to be added inside return()



    return (
        <>    
            <GlobalContextProvider>
                <GoogleOAuthProvider
                    clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID}
                >
                    <BrowserRouter>
                        {isLoggedIn ? (
                            <Sidebar>
                                <Routes>
                                    {/* Routes for logged-in users */}
                                    <Route
                                        path="/dashboard"
                                        element={<Dashboard />}
                                    />
                                    <Route
                                        path="/discussion"
                                        element={<Discussion />}
                                    />
                                    <Route
                                        path="/plexusmd"
                                        element={
                                            <PmdContextProvider>
                                                <PlexusMD />
                                            </PmdContextProvider>
                                        }
                                    />
                                    <Route
                                        path="/newsfeed"
                                        element={
                                            <FeedContextProvider>
                                                <NewsFeed />
                                            </FeedContextProvider>
                                        }
                                    />
                                    <Route
                                        path="/reports"
                                        element={
                                            <ReportContextProvider>
                                                <Reports />
                                            </ReportContextProvider>
                                        }
                                    />
                                    <Route
                                        path="/banner"
                                        element={
                                            <BannerContextProvider>
                                                <Banner />
                                            </BannerContextProvider>
                                        }
                                    />
                                    <Route
                                        path="/user"
                                        element={
                                            <UserContextProvider>
                                                <User />
                                            </UserContextProvider>
                                        }
                                    />

                                    <Route
                                        path="/user-verification"
                                        element={
                                            <UserContextProvider>
                                               <VerificationPage /> 
                                            </UserContextProvider>
                                        }
                                    />

                                    <Route
                                        path="/club"
                                        element={
                                            <ClubContextProvider>
                                                <Club />
                                            </ClubContextProvider>
                                        }
                                    />
                                    <Route
                                        path="/notification"
                                        element={
                                            <NotificationContextProvider>
                                                <Notification />
                                            </NotificationContextProvider>
                                        }
                                    />
                                    <Route path="/quiz" element={<Quiz />} />
                                    <Route
                                        path="/ad-stats-1"
                                        element={<AdsDash/> }
                                    />
                                    <Route
                                        path="/linkzap"
                                        element={<LinkZap/> }
                                    />
                                    <Route path="/wa-template-helper" element={<WATemplatesHelper/> } />
                                </Routes>
                            </Sidebar>
                        ) : (
                            <Routes>
                                <Route
                                    path="/"
                                    element={<Navigate to="/login" />}
                                />
                                <Route path="/login" element={<Login />} />
                            </Routes>
                        )}

                        {isLoggedIn && <FloatingAddButton />}
                        {true && <UploaderModal />}
                        {true && <Messenger />}
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </GlobalContextProvider>
        </>
    );
}

export default App;
