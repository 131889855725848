/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.esm.js';
import axios from 'axios';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
    Button,
    Modal,
    Form,
    Image,
    InputGroup,
    Container,
    Col,
} from 'react-bootstrap';

// Components imports
import './AddDiscussion.css';
import {
    DiscussionContext,
    useGlobalContext,
} from '../../../Context/index.tsx';
import { globalURLS } from '../../../Global/urls.js';
import BackGroundInputSelector from '../../Common/Inputs/BackGroundInputSelector.tsx';
import useDiscussionsClubsHostsInternalHosts from '../../../hooks/useDiscussionsClubsHostsInternalHosts.tsx';

const initalBrandConfig = {
    logoUrl: null,
    posterUrl: null,
    logoUrlModal: null,
    detailText: null,
    sizeMultiple: 1,
};
function AddDiscussion() {
    const { showErrorToast, setShowSuccessToast, setShowErrorToast } =
        useGlobalContext();

    // const [showBtn, setShowBtn ]= useState(false);
    const [title, setTitle] = useState();
    const [clubs, setClubs] = useState([]);
    const [club, setClub] = useState({});
    const [image, setImage] = useState();
    const [synopsis, setSynopsis] = useState();
    const [summary, setSummary] = useState();
    const [jpegImage, setJpegImage] = useState();
    const [webImage, setWebImage] = useState();
    const [test, setTest] = useState(0);
    const [isPfizerQuiz, setIsPfizerQuiz] = useState(0);
    const [isPrivate, setIsPrivate] = useState(0);
    const [hideFromUpcoming, sethideFromUpcoming] = useState(0);
    const [date, setDate] = useState();
    const [time, setTime] = useState();
    const [tageFor, setTagFor] = useState();
    const [conferenceid, setConferenceId] = useState('');
    const [specialties, setSpecialties] = useState([]);
    const [brandlogo, setBrandLogo] = useState('');
    const [arraySpec, setArraySpec] = useState([]);
    const [arraySpeak, setArraySpeak] = useState([]);
    const [tags, setTags] = useState([]);
    const [arrayTags, setArrayTags] = useState([]);
    const [internalHosts, setInternalHosts] = useState([]);
    const [arrayInternalHosts, setArrayInternalHosts] = useState([]);
    const [arrayModerators, setArrayModerators] = useState([]);
    const [arrayADSpotsAdmins, setArrayADSpotsAdmins] = useState([]);
    const [selectedBackgroundImages, setSelectedBackgroundImages] = useState(
        [],
    );

    const [extraData, setExtraData] = useState({
        oneLiner: '',
        certifier: '',
        replayWontBeAvaliable: false,
        isCertified: false,
        isQuizDiscussion: false,
        isPartnerDiscussion: false,
        stopAutomatedComms: false
    });

    const { selectSpeakers, selectSpecialties } =
        useDiscussionsClubsHostsInternalHosts({
            getSelectSpeakers: true,
            getSelectSpecialties: true,
            isDisabled: true,
        });

    const [validated, setValidated] = useState(false);
    const [brandConfig, setBrandConfig] = useState(initalBrandConfig);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [axiosRespData, setAxiosRespData] = useState({});

    const { showModal, setShowModal } = useContext(
        DiscussionContext.AddDiscussionContext,
    );

    const predefinedBGImageOptions = [
        {
            url: 'https://plexusmd.gumlet.io/ChangingPractice2_1692972466354.png',
            name: 'Changing Practice',
        },
        {
            url: 'https://plexusmd.gumlet.io/WizardsVirtualBG_1695387830279.png',
            name: 'Pfizer',
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const clubsData = await axios.get(globalURLS.clubsJSONURL);
            const specialtiesData = await axios.get(
                globalURLS.specialtiesJSONURL,
            );
            const speakersData = await axios.get(globalURLS.speakersJSONURL);
            const internalHostsData = await axios.get(
                globalURLS.internalHostsJSONURL,
            );

            const tagsData = await axios.post(
                `${globalURLS.baseURL}ADgetTags`,
                globalURLS.config,
            );

            setTags(tagsData?.data?.data);
            setClubs(clubsData.data);
            setSpecialties(specialtiesData.data || []);
            setInternalHosts(internalHostsData.data || []);
        };
        fetchData();
    }, []);

    const animatedComponents = makeAnimated();
    let utcDate = null;
    if (date && date.length && time && time.length) {
        utcDate = zonedTimeToUtc(`${date + ' ' + time}`, 'Asia/Calcutta');
    }

    const btnclose = () => {
        setShowModal(false);
        //  window.location.reload(true)
    };

    const handleClub = val => {
        setClub(val);
    };

    const handleBackgroundImageChange = selectedImages => {
        setSelectedBackgroundImages(selectedImages);
    };

    const handleExtraData = e => {
        const { value, name } = e.target;

        if (
            name === 'replayWontBeAvaliable' ||
            name === 'isCertified' ||
            name === 'isQuizDiscussion' ||
            name === 'isPartnerDiscussion' ||
            name === 'stopAutomatedComms'
        ) {
            setExtraData({
                ...extraData,
                [name]:
                    typeof extraData[name] === 'boolean'
                        ? !extraData[name]
                        : true,
            });
        } else {
            setExtraData({ ...extraData, [name]: value });
        }
    };

    const handleBrandChange = e => {
        const { value, name } = e.target;

        if (name === 'posterURL') {
            setBrandLogo(value);
            setBrandConfig({ ...brandConfig, posterUrl: value.trim(), detailText: value.trim().length ? `![Promo](${value}) ${brandConfig.detailText || ''}` : brandConfig.detailText });
        }

        if (name === 'brandLogoSmall') {
            setBrandLogo(value);
            setBrandConfig({ ...brandConfig, logoUrl: value.trim() });
        }

        if (name === 'detailText') {
            setBrandConfig({
                ...brandConfig,
                detailText: value,
            });
        }

        if (name === 'brandLogoFull') {
            setBrandConfig({ ...brandConfig, logoUrlModal: value.trim() });
        }

        if (name === 'scale') {
            setBrandConfig({ ...brandConfig, sizeMultiple: Number(value) });
        }
    };
    const handleTitle = e => {
        setTitle(e.target.value);
    };

    const handleImage = e => {
        setImage(e.target.value);
    };

    const handleSynopsis = e => {
        setSynopsis(e.target.value);
    };

    const handleTest = () => {
        setTest(test ? 0 : 1);
    };

    const handleTagFor = event => {
        const { value } = event.target;

        setClub({});
        setConferenceId(null);
        setTagFor(value || null);
    };

    const handlePfizerQuiz = () => {
        setIsPfizerQuiz(isPfizerQuiz ? 0 : 1);
        setExtraData({
            ...extraData,
            isQuizDiscussion: extraData?.isQuizDiscussion ? false : true,
        });
    };

    const handlePrivate = () => {
        setIsPrivate(isPrivate ? 0 : 1);
    };

    const handlehideFromUpcoming = () => {
        sethideFromUpcoming(!hideFromUpcoming);
    };

    const handleDate = e => {
        setDate(e.target.value);
    };

    const handleTime = e => {
        setTime(e.target.value);
    };

    const handleTags = val => {
        setArrayTags(val);
    };

    const handleSpecialties = val => {
        setArraySpec(val);
    };

    const handleSpeakers = val => {
        setArraySpeak(val);
    };

    const handleInternalHosts = val => {
        setArrayInternalHosts(val);
    };

    const handleModerators = val => {
        setArrayModerators(val);
    };

    const handleADSportAdmins = val => {
        setArrayADSpotsAdmins(val);
    };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        const res = await axios.post(
            `${globalURLS.baseURL}addDiscussions`,
            { discussions: [data] },
            globalURLS.config,
        );

        if ((res.data.data.success[0], res?.data?.success === true)) {
            setAxiosRespData(res.data.data.success[0]);
            setShowModal(false);
            setShowSuccessToast(true);
            window.location.reload();
        } else {
            setShowErrorToast(true);
        }
    };

    // const renderListOfClubs = (clubItems) => {
    //   return clubItems.map((clubItem) => (
    //      <option key={clubItem.id} value={clubItem.clubid}>{clubItem.name}</option>
    //   ))
    // }

    const clubsData = clubs.map(el => ({
        value: el.clubid,
        label: el.name,
    }));

    const specialtiesData = specialties.map(el => ({
        value: el.id,
        label: el.name,
    }));

    let data = {};

    if (title && title.length) data.name = title;
    if (club?.value) data.clubid = [club.value];
    if (utcDate) data.startdate = utcDate;
    if (image && image.length) data.image = [image];
    if (brandlogo) data.brandlogo = brandlogo;
    if (brandConfig) data.brandConfig = brandConfig;
    if (synopsis && synopsis.length) data.synopsis = synopsis;
    if (conferenceid) data.conferenceid = conferenceid;
    if (summary) data.summary = summary;
    if (jpegImage) data.jpegImage = jpegImage;
    if (webImage) data.webImage = webImage;
    data.isTest = test;
    data.isPfizerQuiz = isPfizerQuiz;
    data.isPrivate = isPrivate;
    data.hideFromUpcoming = !!hideFromUpcoming;
    data.virtualBackgroundImages = {
        virtualBackgroundImages:
            Array.isArray(selectedBackgroundImages) &&
            selectedBackgroundImages.length
                ? selectedBackgroundImages
                : [],
    };

    data.extraData = extraData;

    if (
        (arraySpeak && arraySpeak.length) ||
        (arrayInternalHosts && arrayInternalHosts.length)
    ) {
        let hostsData = [];
        let allHostsData = [];

        if (Array.isArray(arraySpeak)) {
            let data = arraySpeak.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 1,
                rank: idx,
                type: 'host',
                iscommentmoderator: false,
                adSpotAdmin: false,
            }));

            hostsData.push(...data);
        }

        if (arrayInternalHosts?.length) {
            let data = arrayInternalHosts.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 0,
                rank: idx,
                type: 'host',
                iscommentmoderator: false,
                adSpotAdmin: false,
            }));

            hostsData.push(...data);
        }

        if (arrayModerators?.length) {
            let data = arrayModerators.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 0,
                rank: idx,
                type: 'host',
                iscommentmoderator: true,
                adSpotAdmin: false,
            }));

            hostsData.push(...data);
        }

        if (arrayADSpotsAdmins?.length) {
            let data = arrayADSpotsAdmins.map((el, idx) => ({
                accountid: el.value,
                featureasspeaker: 0,
                rank: idx,
                type: 'host',
                iscommentmoderator: false,
                adSpotAdmin: true,
            }));

            hostsData.push(...data);
        }

        allHostsData.push(...hostsData);
        // console.log('allHostsData', allHostsData);
        data.hosts = allHostsData?.reduce((accumulator, current) => {
            const existingObject = accumulator.find(
                item => item.accountid === current.accountid,
            );

            if (existingObject) {
                existingObject.iscommentmoderator =
                    existingObject.iscommentmoderator ||
                    current.iscommentmoderator;
                existingObject.adSpotAdmin =
                    existingObject.adSpotAdmin || current.adSpotAdmin;
                existingObject.iscommentmoderator =
                    existingObject.iscommentmoderator ||
                    current.iscommentmoderator;
            } else {
                accumulator.push(current);
            }

            return accumulator;
        }, []);
    }

    if (arrayTags && arrayTags?.length) {
        data.tagIds = arrayTags?.map(tag => tag.value);
    }

    if (arraySpec && arraySpec.length)
        data.specialityIds = arraySpec.map(e => e.value);

    return (
        <Container className="discussion-container">
            <Col>
                <Modal show={showModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Discussion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* Title */}
                            <InputGroup className="row mt-3">
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    Title
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    required
                                    type="text"
                                    placeholder="Discussion Title"
                                    value={title}
                                    onChange={handleTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a discussion title.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* One Liner */}
                            <InputGroup className="row mt-3">
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    One Liner
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    // required
                                    name="oneLiner"
                                    type="text"
                                    placeholder="One Liner"
                                    value={extraData?.oneLiner}
                                    onChange={handleExtraData}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a One Liner.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* Certifier */}
                            <InputGroup className="row mt-3">
                                <Form.Label className="m-2" htmlFor="basic-url">
                                    Certifier
                                </Form.Label>
                                <Form.Control
                                    className="mx-3"
                                    // required
                                    name="certifier"
                                    type="text"
                                    placeholder="Certifier"
                                    value={extraData?.certifier}
                                    onChange={handleExtraData}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a One Liner.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* Date and Time */}
                            <InputGroup className="row">
                                <Form.Label
                                    className="m-2 mt-3"
                                    htmlFor="basic-url"
                                >
                                    Date and Time
                                </Form.Label>
                                {/* Date */}
                                <Form.Control
                                    className="m-2"
                                    type="date"
                                    required
                                    value={date}
                                    onChange={handleDate}
                                />
                                {/* Time */}
                                <Form.Control
                                    className="m-2"
                                    type="time"
                                    required
                                    value={time}
                                    onChange={handleTime}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please select discussion date and time.
                                </Form.Control.Feedback>
                            </InputGroup>

                            {/* Select Tagging For Club Or Conference */}
                            <Form.Group controlId="tagFor">
                                <Form.Label>Tag For</Form.Label>
                                <Form.Select
                                    name="tagFor"
                                    value={tageFor}
                                    onChange={handleTagFor}
                                >
                                    <option value={''}>None</option>

                                    <option value={'club'}>Club</option>
                                    <option value={'conference'}>
                                        Conference
                                    </option>
                                </Form.Select>
                            </Form.Group>

                            {/* Club */}
                            {tageFor === 'club' && (
                                <InputGroup className="row mt-3">
                                    <Form.Label htmlFor="basic-url">
                                        Select Club
                                    </Form.Label>
                                    <Select
                                        onChange={val => handleClub(val)}
                                        isSearchable
                                        options={JSON.parse(
                                            JSON.stringify(clubsData),
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a club.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            )}

                            {/* Conference */}
                            {tageFor === 'conference' && (
                                <InputGroup className="row mt-3">
                                    <Form.Label htmlFor="basic-url">
                                        Conference ID
                                    </Form.Label>
                                    <Form.Control
                                        id="conferenceid"
                                        name="conferenceid"
                                        aria-describedby="basic-addon3"
                                        value={conferenceid}
                                        onChange={e =>
                                            setConferenceId(
                                                e?.target?.value || '',
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please add conference id.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            )}

                            {/* Tag */}
                            <InputGroup className="row mt-3">
                                <Form.Label htmlFor="basic-url">
                                    Select Tags
                                </Form.Label>
                                <Select
                                    components={animatedComponents}
                                    onChange={val => handleTags(val)}
                                    isSearchable
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={tags || []}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please select a club.
                                </Form.Control.Feedback>
                            </InputGroup>
                            {/* Specialties */}
                            <InputGroup className="row mt-3">
                                <Form.Label htmlFor="basic-url">
                                    Select Specialties
                                </Form.Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    onChange={val => handleSpecialties(val)}
                                    options={JSON.parse(
                                        JSON.stringify(specialtiesData),
                                    )}
                                />
                            </InputGroup>
                            {/* Hosts */}
                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select Featured Hosts
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={val => handleSpeakers(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />
                            {/* Internal Hosts */}
                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select Internal Hosts
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={val => handleInternalHosts(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />

                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select Comment Moderators
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti={true}
                                onChange={val => handleModerators(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />

                            <Form.Label className="mt-3" htmlFor="basic-url">
                                Select AD Spot Admins
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti={true}
                                onChange={val => handleADSportAdmins(val)}
                                options={JSON.parse(
                                    JSON.stringify(selectSpeakers || []),
                                )}
                            />
                            {/* Image */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Image URL
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                value={image}
                                onChange={handleImage}
                            />
                            {image && (
                                <Image
                                    src={image}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            {/* Jpeg Image */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                JPEG Image URL
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                value={jpegImage}
                                onChange={e =>
                                    setJpegImage(e?.target?.value || '')
                                }
                            />
                            {jpegImage && (
                                <Image
                                    src={jpegImage}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            {/* Web Image */}
                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Web Image URL (3:1)
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                value={webImage}
                                onChange={e =>
                                    setWebImage(e?.target?.value || '')
                                }
                            />
                            {webImage && (
                                <Image
                                    src={webImage}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Brand Logo Small
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                name="brandLogoSmall"
                                aria-describedby="basic-addon3"
                                value={brandConfig?.logoUrl}
                                onChange={handleBrandChange}
                            />

                            {brandConfig?.logoUrl && (
                                <Image
                                    src={brandConfig?.logoUrl}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            {/* <Form.Label htmlFor="basic-url" className="mt-3">
                                Brand Logo Full
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                name="brandLogoFull"
                                aria-describedby="basic-addon3"
                                value={brandConfig?.logoUrlModal || ''}
                                onChange={handleBrandChange}
                            />
                            {brandConfig?.logoUrlModal && (
                                <Image
                                    src={brandConfig?.logoUrlModal}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )} */}

                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Brand Logo Full
                            </Form.Label>
                            <Form.Control
                                id="basic-url"
                                name="posterURL"
                                aria-describedby="basic-addon3"
                                value={brandConfig?.posterUrl || ''}
                                onChange={handleBrandChange}
                            />
                            {brandConfig?.posterUrl && (
                                <Image
                                    src={brandConfig?.posterUrl}
                                    fluid={true}
                                    style={{
                                        width: '80vw',
                                        height: '45vh',
                                    }}
                                />
                            )}

                            <Form.Label htmlFor="basic-url" className="mt-3">
                                Brand Logo Detail Text
                            </Form.Label>
                            <Form.Control
                                id="basic-detailText"
                                name="detailText"
                                aria-describedby="basic-detailText"
                                value={brandConfig?.detailText || ''}
                                onChange={handleBrandChange}
                            />

                            <Form.Group>
                                <Form.Label>Logo Scale</Form.Label>
                                <Form.Control
                                    type="number"
                                    name={`scale`}
                                    value={brandConfig.sizeMultiple}
                                    defaultValue={1}
                                    onChange={handleBrandChange}
                                    min="1"
                                />
                            </Form.Group>

                            {/* BG Selector */}

                            <Form.Group
                                className="mt-5"
                                style={{
                                    backgroundColor: '#BFBFBF',
                                    height: 'auto',
                                    margin: '4px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <h5 style={{ margin: '15px 0px' }}>
                                    {' '}
                                    Virtual Background Image Selection
                                </h5>

                                <BackGroundInputSelector
                                    predefinedOptions={predefinedBGImageOptions}
                                    onChange={handleBackgroundImageChange}
                                    selectedImages={selectedBackgroundImages}
                                />
                            </Form.Group>

                            {/* Synopsis */}
                            <Form.Label className="mt-3">Synopsis </Form.Label>
                            <Form.Control
                                required
                                as="textarea"
                                aria-label="With textarea"
                                value={synopsis}
                                onChange={handleSynopsis}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a synopsis.
                            </Form.Control.Feedback>

                            {/* summary */}
                            <Form.Label className="mt-3">Summary </Form.Label>
                            <Form.Control
                                as="textarea"
                                aria-label="With textarea"
                                value={summary}
                                onChange={e =>
                                    setSummary(e?.target?.value || '')
                                }
                            />

                            {/* IsTest */}
                            <InputGroup className="mt-3">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Test"
                                    checked={test === 1}
                                    value={test}
                                    onChange={handleTest}
                                />

                                {/* {handlePfizerQuiz} */}
                                <Form.Check
                                    className="mx-3"
                                    type="switch"
                                    id="custom-switch"
                                    label="Pfizer Quiz"
                                    checked={isPfizerQuiz === 1}
                                    value={isPfizerQuiz}
                                    onChange={handlePfizerQuiz}
                                />

                                {/* isPrivate */}
                                <Form.Check
                                    className="mx-3"
                                    type="switch"
                                    id="custom-switch"
                                    label="Private"
                                    checked={isPrivate === 1}
                                    value={isPrivate}
                                    onChange={handlePrivate}
                                />

                                {/* hideFromUpcoming */}  
                                <Form.Check
                                    className="mx-3"
                                    type="switch"
                                    id="custom-switch"
                                    label="Hide From Upcoming"
                                    checked={!!hideFromUpcoming}
                                    value={hideFromUpcoming}
                                    onChange={handlehideFromUpcoming}
                                />
                            </InputGroup>

                            {/* Extra Data */}
                            <InputGroup className="mt-3">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    name="replayWontBeAvaliable"
                                    label="Replay Wont Be Avaliable"
                                    checked={
                                        extraData?.replayWontBeAvaliable ===
                                        true
                                    }
                                    value={extraData?.replayWontBeAvaliable}
                                    onChange={handleExtraData}
                                />

                                {/* {Is Certified} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    type="switch"
                                    id="custom-switch"
                                    name="isCertified"
                                    label="Certified"
                                    checked={extraData?.isCertified === true}
                                    value={extraData?.isCertified}
                                    onChange={handleExtraData}
                                />

                                {/* {Is Quiz} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    type="switch"
                                    id="custom-switch"
                                    name="isQuizDiscussion"
                                    label="Quiz"
                                    checked={
                                        extraData?.isQuizDiscussion === true
                                    }
                                    value={extraData?.isQuizDiscussion}
                                    onChange={handleExtraData}
                                />

                                {/* {Is Partner Discussion} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    type="switch"
                                    id="custom-switch"
                                    name="isPartnerDiscussion"
                                    label="Partner Discussion"
                                    checked={
                                        extraData?.isPartnerDiscussion === true
                                    }
                                    value={extraData?.isPartnerDiscussion}
                                    onChange={handleExtraData}
                                />

                                {/* {To Stop All Automated Comms} */}
                                <Form.Check
                                    style={{ marginLeft: '10px' }}
                                    type="switch"
                                    id="custom-switch"
                                    name="stopAutomatedComms"
                                    label="Stop Automated Communications"
                                    checked={
                                        extraData?.stopAutomatedComms === true
                                    }
                                    value={extraData?.stopAutomatedComms}
                                    onChange={handleExtraData}
                                />
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={btnclose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={showErrorToast}
                            >
                                Add Discussion
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
}

export default AddDiscussion;
